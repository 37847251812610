import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders countdown clock square face.
 */
class CountdownClockFaceSquare extends HTMLElement implements CustomElement {
  static get observedAttributes() {
    return ['data-value'];
  }

  readonly dataset!: {
    /**
     * Represents current day/hour/minute/second value, changing this triggers rendering.
     */
    value: string;
  };

  public shadowRoot!: ShadowRoot;
  private currentValue?: number;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  connectedCallback() {
    this.render();
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.isConnected && name === 'data-value' && oldValue !== newValue) {
      this.render();
    }
  }

  render() {
    const face = this.shadowRoot.querySelector<HTMLElement>('.countdown-clock__square');
    const value = parseInt(this.dataset.value, 10);

    if (value !== this.currentValue) {
      if (this.currentValue >= 0) {
        const currentValue =
          this.currentValue < 10 ? `0${this.currentValue}` : `${this.currentValue}`;
        const backElement = this.shadowRoot.querySelector('.back');
        backElement.setAttribute('data-value', currentValue || '');

        const bottomElement = this.shadowRoot.querySelector('.bottom');
        bottomElement.setAttribute('data-value', currentValue || '');
      }

      this.currentValue = value;
      const topElement = this.shadowRoot.querySelector('.top');
      topElement.textContent = value < 10 ? `0${value}` : `${value}`;

      const backBottomElement = this.shadowRoot.querySelector('.back .bottom');
      backBottomElement.setAttribute('data-value', value < 10 ? `0${value}` : `${value}`);

      face.classList.remove('flip');

      // TODO: this is a dead expression, why is it here?

      // forces repaint before reapplying class to make flip animation visible
      // TODO: Replace with solution which does not rely on getter side effects triggering repaint
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      face.offsetWidth;
      face.classList.add('flip');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'countdown-clock-face-square': CountdownClockFaceSquare;
  }
}

if (!customElements.get('countdown-clock-face-square')) {
  customElements.define('countdown-clock-face-square', CountdownClockFaceSquare);
}
