import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders countdown clock circle face.
 */
class CountdownClockFaceCircle extends HTMLElement implements CustomElement {
  static get observedAttributes() {
    return ['data-value'];
  }

  public shadowRoot!: ShadowRoot;

  readonly dataset!: {
    /**
     * Helps display max value for currently rendered part of countdown ex. minute
     */
    total: string;
    /**
     * Represents current day/hour/minute/second value, changing this triggers rendering.
     */
    value: string;
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  connectedCallback() {
    // circle radius in pixels
    const radius = 30;
    const barElement = this.shadowRoot.querySelector<HTMLElement>('.bar');
    barElement.style.strokeDasharray = `${2 * Math.PI * radius}`;

    this.render();
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.isConnected && name === 'data-value' && oldValue !== newValue) {
      this.render();
    }
  }

  render() {
    // circle radius in pixels
    const radius = 30;
    const value = parseInt(this.dataset.value, 10);
    const total = parseInt(this.dataset.total, 10);

    const barElement = this.shadowRoot.querySelector<HTMLElement>('.bar');
    const valueElement =
      this.shadowRoot.querySelector<HTMLElement>('.countdown-clock__unit__value');
    if (!barElement) {
      return;
    }

    barElement.style.strokeDashoffset = `${(1 - value / total) * (2 * Math.PI * radius)}`;
    valueElement.textContent = value < 10 ? `0${value}` : `${value}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'countdown-clock-face-circle': CountdownClockFaceCircle;
  }
}

if (!customElements.get('countdown-clock-face-circle')) {
  customElements.define('countdown-clock-face-circle', CountdownClockFaceCircle);
}
