import './countdown-clock/index.js';
import './countdown-clock-face-circle/index.js';
import './countdown-clock-face-square/index.js';

function onDOMContentLoaded(_event: Event) {
  // We must query the DOM to determine if a flash sale countdown timer override exists. If none is
  // found we have no need to continue further and short circuit.
  const overrideEl = document.querySelector<HTMLElement>('#product-detail-countdown-timer');
  if (!overrideEl) {
    return;
  }

  if (!overrideEl.dataset.startTime || !overrideEl.dataset.endTime) {
    return;
  }

  const flashStartTime = parseInt(overrideEl.dataset.startTime, 10);
  const flashEndTime = parseInt(overrideEl.dataset.endTime, 10);
  const timestamp = Math.floor(Date.now() / 1000);
  if (timestamp > flashStartTime && timestamp < flashEndTime) {
    let appendTimer = false;
    let timerEl = document.querySelector<HTMLElement>('countdown-clock');

    // Since the global countdown timer is uniquely shown on various collection pages we must check
    // if it exists and create the element to be inserted into the DOM after all dataset attributes
    // have been updated to ensure proper rendering.
    if (!timerEl) {
      timerEl = document.createElement('countdown-clock');
      timerEl.dataset.themeTextColor = '#000000';
      timerEl.dataset.faceStyle = 'default';
      appendTimer = true;
    }

    timerEl.dataset.startTime = overrideEl.dataset.startTime;
    timerEl.dataset.endTime = overrideEl.dataset.endTime;

    if (overrideEl.dataset.heading) {
      timerEl.dataset.title = overrideEl.dataset.heading;
    }

    if (appendTimer) {
      overrideEl.appendChild(timerEl);
    }
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
