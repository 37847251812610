import type { CustomElement } from '@integrabeauty/custom-elements';
import { startTimer, timeLeft } from '../../../lib/countdown-timer.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders countdown clock
 */
class CountdownClock extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-update',
      'data-end-time',
      'data-start-time'
    ];
  }

  readonly dataset!: {
    /**
     * The text of the call to action.
     */
    ctaText: string;

    /**
     * The url to navigate to when a visitor clicks the call to action.
     */
    ctaUrl: string;

    /**
     * Countdown clock element description.
     */
    description: string;

    /**
     * Stop time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    endTime: string;

    /**
     * Set visual stale for countdown clock, change it from default numbers to square flip or
     * circles.
     *
     * @example 'circle' | 'square'
     */
    faceStyle: string;

    /**
     * Start time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    startTime: string;

    /**
     * Countdown element background color, hex format.
     */
    themeBgColor: string;

    /**
     * CTA button text and border color, hex format.
     */
    themeCtaColor: string;

    /**
     * Countdown element description color, hex format.
     */
    themeDescColor: string;

    /**
     * Text color for the countdown clock element, hex format.
     */
    themeTextColor: string;

    /**
     * Countdown element title color, hex format.
     */
    themeTitleColor: string;

    /**
     * Countdown clock element title.
     */
    title: string;

    /**
     * Set this attribute to a new value to trigger a render.
     */
    update: string;
  };

  public shadowRoot!: ShadowRoot;
  private days?: string;
  private hours?: string;
  private minutes?: string;
  private seconds?: string;
  private remainingText = '';
  private shouldShowTimer: boolean;

  private onTimerTickBound = this.onTimerTick.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
    this.shouldShowTimer = false;
  }

  public connectedCallback() {
    if (!this.dataset.endTime || !this.dataset.startTime) {
      return;
    }

    const now = Date.now();
    const startTime = parseInt(this.dataset.startTime, 10) * 1000;
    const endTime = parseInt(this.dataset.endTime, 10) * 1000;

    if (now > startTime && now < endTime) {
      // render face style if set
      const faceStyle = this.dataset.faceStyle;

      if (faceStyle === 'circle' || faceStyle === 'square') {
        const renderFn = faceStyle === 'square' ? createSquareFaceElement : createCircleFaceElement;

        const container = this.shadowRoot.querySelector('.countdown-clock__container');
        container.classList.add(faceStyle);

        const clockUnitNames = ['days', 'hours', 'minutes', 'seconds'];
        for (const unitName of clockUnitNames) {
          const unit =
            this.shadowRoot.querySelector<HTMLElement>(`.countdown-clock__unit__value.${unitName}`);
          unit.parentElement.replaceChild(renderFn(unitName), unit);
        }
      }

      startTimer();
      addEventListener('timer-tick', this.onTimerTickBound);
    } else {
      this.shadowRoot.host.classList.add('hidden');
    }
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.isConnected && name === 'data-update' && newValue !== oldValue) {
      this.render();
    }
  }

  public disconnectedCallback() {
    removeEventListener('timer-tick', this.onTimerTickBound);
  }

  private onTimerTick(event: WindowEventMap['timer-tick']) {
    const endTime = parseInt(this.dataset.endTime, 10) * 1000;

    const remaining = timeLeft(event.detail.timestamp, endTime);

    if (remaining) {
      // Display the result
      this.days = normalizeTimeItem(remaining.days);
      this.hours = normalizeTimeItem(remaining.hours);
      this.minutes = normalizeTimeItem(remaining.minutes);
      this.seconds = normalizeTimeItem(remaining.seconds);
      this.remainingText = remaining.text;
      this.shouldShowTimer = true;

      if (this.dataset.faceStyle === 'circle' || this.dataset.faceStyle === 'square') {
        this.runFaceTimer(remaining.days, remaining.hours, remaining.minutes, remaining.seconds);
      }
    } else {
      this.shouldShowTimer = false;
    }

    this.dataset.update = Date.now().toString();
  }

  private runFaceTimer(days: number, hours: number, minutes: number, seconds: number) {
    const startTime = parseInt(this.dataset.startTime, 10) * 1000;
    const endTime = parseInt(this.dataset.endTime, 10) * 1000;

    const daysElement = this.shadowRoot.querySelector<HTMLElement>('.days');
    daysElement.dataset.value = `${days}`;
    daysElement.dataset.total = `${Math.trunc(endTime - startTime) / (24 * 60 * 60 * 1000)}`;

    const hoursElement = this.shadowRoot.querySelector<HTMLElement>('.hours');
    hoursElement.dataset.value = `${hours}`;
    hoursElement.dataset.total = '60';

    const minutesElement = this.shadowRoot.querySelector<HTMLElement>('.minutes');
    minutesElement.dataset.value = `${minutes}`;
    minutesElement.dataset.total = '60';

    const secondsElement = this.shadowRoot.querySelector<HTMLElement>('.seconds');
    secondsElement.dataset.value = `${seconds}`;
    secondsElement.dataset.total = '60';
  }

  private render() {
    const containerElement = this.shadowRoot.querySelector('.countdown-clock__container');
    if (!this.shouldShowTimer) {
      // BUG: For some unclear reason, we see errors in Sentry where sometimes the element is not
      // found. The error appears as "Cannot read properties of null (reading 'classList')". As a
      // partial mitigation, we explicitly check for truthiness of containerElement.
      if (containerElement) {
        containerElement.classList.add('hidden');
      }

      return;
    }

    this.renderElement(true, '.countdown-clock__unit__value.days', this.days);
    this.renderElement(true, '.countdown-clock__unit__value.hours', this.hours);
    this.renderElement(true, '.countdown-clock__unit__value.minutes', this.minutes);
    this.renderElement(true, '.countdown-clock__unit__value.seconds', this.seconds);
    this.renderElement(true, '.countdown-clock__container', null,
      { color: this.dataset.themeTextColor, background: this.dataset.themeBgColor });
    this.renderElement(this.dataset.title, '.countdown-clock__title', this.dataset.title,
      { color: this.dataset.themeTitleColor });
    this.renderElement(this.dataset.description, '.countdown-clock__description',
      this.dataset.description, { color: this.dataset.themeDescColor });

    this.renderElement(this.dataset.ctaUrl && this.dataset.ctaText, '.countdown-clock__button',
      this.dataset.ctaText, {
        color: this.dataset.themeCtaColor,
        borderColor: this.dataset.themeCtaColor
      });

    if (this.dataset.ctaUrl && this.dataset.ctaText) {
      const button = this.shadowRoot.querySelector('.countdown-clock__button');
      button.setAttribute('href', this.dataset.ctaUrl);
    }

    const wrapper = this.shadowRoot.querySelector<HTMLElement>('.countdown-clock__wrapper');
    wrapper?.setAttribute('aria-label', this.remainingText);
  }

  private renderElement(shouldRender: boolean | string, selector: string, content?: string,
    style = <Record<string, string>>{}) {
    if (shouldRender) {
      const element = this.shadowRoot.querySelector<HTMLElement>(selector);
      if (!element) {
        return;
      }

      if (content) {
        element.textContent = content;
      }

      element.classList.remove('hidden');

      for (const [prop, value] of Object.entries(style)) {
        element.style.setProperty(prop, value);
      }
    }
  }
}

function createSquareFaceElement(name: string) {
  const squareFaceElement = document.createElement('countdown-clock-face-square');
  squareFaceElement.className = name;
  return squareFaceElement;
}

function createCircleFaceElement(name: string) {
  const circleFaceElement = document.createElement('countdown-clock-face-circle');
  circleFaceElement.className = name;
  return circleFaceElement;
}

/**
 * This will normalize time items to show 01:01:01:01 instead of 1:1:1:1
 */
function normalizeTimeItem(value: number) {
  return value < 10 ? `0${value}` : `${value}`;
}

declare global {
  interface HTMLElementTagNameMap {
    'countdown-clock': CountdownClock;
  }
}

if (!customElements.get('countdown-clock')) {
  customElements.define('countdown-clock', CountdownClock);
}
